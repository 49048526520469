
import React from "react";
import { graphql } from 'gatsby';
import Layout from "../components/layout";
import Block from "../components/block";
import Seo from 'gatsby-plugin-wpgraphql-seo';

const PostTemplate = (props) => {
	const {
		data: {
			postInfo,
			postInfo: {
				blocks
			}
		}
	} = props;

	return (
		<Layout>
			<Seo post={postInfo} />
			{
				blocks && blocks.map((block, index) => {
					return (
						<Block key={index} block={block} post={postInfo} />
					)
				})
			}
		</Layout>
	);
};

export const query = graphql`query ($id: String!) {
  	postInfo: wpPost(id: {eq: $id}) {
		id
		blocks {
			name
			attributesJSON
			innerBlocks {
				name
				attributesJSON
				innerBlocks {
					name
					attributesJSON
				}
			}
		}
		featuredImage {
			node {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(width: 1000)
					}
				}
			}
		}
		title
		date(formatString: "MMMM D, YYYY") 
		seo {
			title
			metaDesc
			focuskw
			metaKeywords
			metaRobotsNoindex
			metaRobotsNofollow
			opengraphTitle
			opengraphDescription
			opengraphImage {
				altText
				sourceUrl
				srcSet
			}
			twitterTitle
			twitterDescription
			twitterImage {
				altText
				sourceUrl
				srcSet
			}
			canonical
			cornerstone
			schema {
				articleType
				pageType
				raw
			}
		}
  	}
}
`;

export default PostTemplate;